<script setup lang="ts">
import { useScopedEventStore } from '@stores/EventStore.ts'
import { onMounted, ref, toRef, watch } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { IEventModel } from '@models/EventModel.ts'
import { useRouter, useRoute } from 'vue-router'
import Tabs from 'primevue/tabs'
import Tab from 'primevue/tab'
import Drawer from 'primevue/drawer'

interface EventViewProps {
    event: IEventModel
}

const props = defineProps<EventViewProps>()

const confirm = useConfirm()
const toast = useToast()
const router = useRouter()
const route = useRoute()

const eventStore = useScopedEventStore(props.event.eventId ?? '')
const sidebarVisible = ref(false)

const eventRef = toRef<IEventModel | undefined>(undefined)
const qrCodeRef = ref('')

const tabValue = ref<string | number>(route.name ? route.name.toString() : 'EventGallery')

watch(
    () => route.name,
    (value) => {
        tabValue.value = value ? value.toString() : 'EventGallery'
    }
)

const tabItems = ref([
    {
        route: { name: 'EventGallery' },
        label: 'Gallery',
        icon: 'pi pi-th-large',
    },
    {
        route: { name: 'EventAlbums' },
        label: 'Albums',
        icon: 'pi pi-camera',
    },
])

const tabEl = ref<HTMLElement | null>(null)

const confirmDelete = () => {
    confirm.require({
        message: 'Do you want to delete this event?',
        header: 'Danger Zone',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Cancel',
        acceptLabel: 'Delete',
        rejectClass: 'p-button-secondary p-button-outlined',
        acceptClass: 'p-button-danger',
        accept: async () => {
            if (!eventRef?.value) {
                toast.add({
                    severity: 'error',
                    summary: 'Error Deleting Event',
                    detail: 'Your event was not deleted',
                    life: 3000,
                })
                return
            }

            try {
                await eventStore.deleteEvent()
            } catch (error) {
                toast.add({
                    severity: 'error',
                    summary: 'Error Deleting Event',
                    detail: 'Your event was not deleted',
                    life: 3000,
                })
                return
            }

            await router.push('/events')

            toast.add({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Event was deleted',
                life: 3000,
            })
        },
        reject: () => {
            toast.add({
                severity: 'error',
                summary: 'Cancelled',
                detail: 'Your event was not deleted',
                life: 3000,
            })
        },
    })
}

const downloadQRCode = () => {
    const link = document.createElement('a')
    link.href = qrCodeRef.value
    link.download = `${eventRef.value?.name
        .toLowerCase()
        .replace(' ', '-')}-qr-code.png`
    link.click()
}

onMounted(async () => {
    console.log(props.event)
    qrCodeRef.value = await props.event.generateCaptureQRCode()
    eventRef.value = props.event
    document.title = `Event - ${props.event.name}`
})

function formatDateTime(startDateTime: string, endDateTime: string) {
    if (!startDateTime && !endDateTime) {
        return 'Not set'
    }

    const startDate = new Date(startDateTime)
    const endDate = new Date(endDateTime)

    const startDateLocale = startDate.toLocaleDateString('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    const endDateLocale = new Date(endDateTime).toLocaleDateString('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    if (startDate.getDate() === endDate.getDate()) {
        return startDateLocale
    }

    return `${startDateLocale} to </br> ${endDateLocale}`
}

function scrollTo(view: HTMLElement | null) {
    view?.scrollIntoView({ behavior: 'smooth' })
}
</script>

<template>
    <div>
        <div
            v-if="eventRef"
            class="flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative w-full"
        >
            <Drawer
                v-model:visible="sidebarVisible"
                class="w-full md:w-20rem lg:w-30rem"
                header="Event Details"
                position="right"
            >
                <div class="text-500 mb-3">
                    Here you will find all the details of your event.
                </div>
                <div
                    class="grid-custom grid-nogutter border-top-1 surface-border pt-2"
                >
                    <div class="col-12 py-2 px-3">
                        <div class="text-500 font-medium mb-2">Name</div>
                        <div class="text-900">{{ eventRef.name }}</div>
                    </div>
                    <div class="col-12 py-2 px-3">
                        <div class="text-500 font-medium mb-2">Description</div>
                        <div class="text-900">
                            {{ eventRef.description }}
                        </div>
                    </div>
                    <div class="col-12 py-2 px-3">
                        <div class="text-500 font-medium mb-2">Start Date</div>
                        <div class="text-900">
                            {{
                                eventRef.startDateTime
                                    ? eventRef.startDateTime
                                    : 'Not set'
                            }}
                        </div>
                    </div>
                    <div class="col-12 py-2 px-3">
                        <div class="text-500 font-medium mb-2">End Date</div>
                        <div class="text-900">
                            {{
                                eventRef.endDateTime
                                    ? eventRef.endDateTime
                                    : 'Not set'
                            }}
                        </div>
                    </div>
                    <div class="col-12 py-2 px-3">
                        <div class="text-500 font-medium mb-2">
                            Capture Link
                        </div>
                        <div class="text-900">
                            <a :href="eventRef.captureUrl" target="_blank">{{
                                eventRef.captureUrl
                            }}</a>
                        </div>
                    </div>
                    <div class="col-12 py-2 px-3">
                        <div class="text-500 font-medium mb-2">QR Code</div>
                        <div class="flex justify-center">
                            <img :src="qrCodeRef" alt="QR Code" />
                        </div>
                    </div>
                    <div class="col-12 py-2 px-3 flex justify-between">
                        <Button
                            class="p-button-outlined mt-2 md:mt-0"
                            icon="pi pi-download"
                            label="Download QR Code"
                            @click="downloadQRCode"
                        ></Button>
                        <Button
                            class="p-button-outlined mt-2 md:mt-0"
                            icon="pi pi-download"
                            label="Go to Albums"
                        ></Button>
                    </div>
                    <div class="col-12 py-2 px-3 flex justify-between">
                        <Button
                            class="mt-2 md:mt-0 p-button-danger w-full"
                            icon="pi pi-trash"
                            label="Delete Event"
                            @click="confirmDelete"
                        ></Button>
                    </div>
                </div>
            </Drawer>
            <div>
                <div
                    class="h-dvh w-full content-center cursor-pointer align-content-between lg:align-content-center"
                    @click="scrollTo(tabEl)"
                >
                    <div
                        class="flex flex-col flex-auto md:flex-row md:justify-evenly w-full md:items-center"
                    >
                        <div class="hidden w-3/4 md:w-1/3 relative md:block">
                            <div class="absolute right-10 event-stats">
                                Currently 200 photos have been captured
                            </div>
                        </div>
                        <div class="w-4/4 md:w-1/3 relative">
                            <div>
                                <img
                                    :src="eventRef.eventImage"
                                    alt="Image"
                                    class="w-full h-full"
                                />
                            </div>
                            <span
                                :style="{
                                    backgroundColor: eventRef.statusColor,
                                }"
                                class="absolute px-2 py-1 border-round-lg text-sm font-normal text-white mt-0 mb-0 bg-opacity-30"
                                style="
                                    backdrop-filter: blur(10px);
                                    top: 3%;
                                    left: 3%;
                                "
                            >
                                {{ eventRef.status }}
                            </span>
                        </div>
                        <div
                            class="w-4/4 md:w-1/3 lg:ml-4 mt-3 lg:mt-0 mb-6 content-center text-center lg:text-left"
                        >
                            <div class="text-900 text-3xl font-medium mb-3">
                                {{ eventRef.name }}
                            </div>
                            <div class="text-900 text-1xl font-medium mb-3">
                                {{ eventRef.description }}
                            </div>
                            <div>
                                <span
                                    v-html="
                                        formatDateTime(
                                            eventRef.startDateTime,
                                            eventRef.endDateTime
                                        )
                                    "
                                >
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-6">
                        <span class="pi pi-chevron-down text-3xl"></span>
                    </div>
                </div>
                <div style="min-height: calc(-100px + 100vh)">
                    <div
                        ref="tabEl"
                        class="mb-3 flex align-items-center w-full sticky top-0 bg-white justify-between z-10 shadow-2"
                    >
                        <div>
                            <Tabs v-model:value="tabValue">
                                <TabList>
                                    <ul
                                        class="surface-card p-0 m-0 list-none flex overflow-x-auto select-none"
                                    >
                                        <Tab
                                            v-for="tab in tabItems"
                                            :key="tab.label"
                                            :value="tab.route.name"
                                            as="li"
                                        >
                                            <router-link
                                                v-if="tab.route"
                                                v-slot="{ href, navigate }"
                                                :to="{ name: tab.route.name }"
                                                custom
                                            >
                                                <a
                                                    v-ripple
                                                    :href="href"
                                                    @click="navigate"
                                                    class="cursor-pointer p-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                                                    :class="{
                                                        'border-blue-500 text-blue-500 hover:border-blue-500':
                                                            tabValue ===
                                                            tab.route.name,
                                                        'text-700 border-transparent':
                                                            tabValue !==
                                                            tab.route.name,
                                                    }"
                                                >
                                                    <i
                                                        :class="tab.icon"
                                                        class="mr-2"
                                                    />
                                                    <span>{{ tab.label }}</span>
                                                </a>
                                            </router-link>
                                        </Tab>
                                    </ul>
                                </TabList>
                            </Tabs>
                        </div>
                        <div class="mr-3">
                            <router-link
                                :to="{
                                    name: 'EventEdit',
                                    params: { eventId: eventRef.eventId },
                                }"
                            >
                                <Button
                                    class="mr-2"
                                    icon="pi pi-plus-circle"
                                    label="Edit Event"
                                ></Button>
                            </router-link>
                            <Button
                                class="p-button-outlined p-button-rounded"
                                icon="pi pi-ellipsis-v"
                                @click="sidebarVisible = true"
                            ></Button>
                        </div>
                    </div>
                    <div class="m-6">
                        <slot :event="eventRef"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
:root {
    --p-tabs-tablist-background: none;
    --p-tabs-tablist-border-width: 0;
    --p-tabs-tablist-border-color: transparent;
    --p-tabs-tab-padding: 0;
    --p-tabs-tab-margin: 0;
    --p-tabs-tab-border-width: 0;
}

.event-stats {
    transform: translateX(50%) rotate(-90deg) !important;
}
</style>
